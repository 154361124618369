<template>
  <v-card>
    <v-card-text>
      <v-combobox
        v-model="tags"
        :items="[]"
        label="Team Tags"
        :disabled="!editing"
        :search-input.sync="search"
        chips multiple deletable-chips small-chips
      >
        <template v-slot:no-data>
          <v-list-item v-if="search">
            <v-list-item-content>
              <v-list-item-title>
                No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-combobox>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-end">
      <v-fab-transition><!-- CANCEL -->
        <v-btn
          color="success white--text"
          fab
          small
          @click.stop="onSaveClick"
          v-if="editing"
          :loading="loading"
          :disabled="!dirty"
        >
          <v-icon>fas fa-save</v-icon>
        </v-btn>
    </v-fab-transition>
    <v-fab-transition><!-- EDIT/SAVE -->
      <v-btn
        v-if="fab"
        :color="fab.color"
        fab
        small
        :key="fab.key"
        @click.stop="fab.click"
        :loading="fab.loading"
        :disabled="fab.disabled"
      >
        <v-icon>{{fab.icon}}</v-icon>
      </v-btn>
    </v-fab-transition>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['team'],
  data () {
    return {
      iTags: null,
      editing: false,
      loading: false,
      search: null
    }
  },
  computed: {
    tags: {
      get () {
        return this.editing ? this.iTags : this.team.tags
      },
      set (val) {
        this.iTags = val
      }
    },
    fab () {
      return this.editing ? {
        key: 'cancel',
        color: 'error white--text',
        icon: 'fas fa-times',
        click: this.cancel,
        loading: this.loading
      } : {
        key: 'edit',
        color: 'color3 color3Text--text',
        icon: 'fas fa-pencil',
        click: this.edit,
        loading: false,
        disabled: this.loading
      }
    },
    dirty () {
      const a = this.team.tags ? [...this.team.tags].sort() : []
      const b = this.iTags ? [...(this.iTags)].sort() : []
      return JSON.stringify(a) !== JSON.stringify(b)
    }
  },
  methods: {
    cancel () {
      this.search = null
      this.iTags = null
      this.editing = false
    },
    edit () {
      this.iTags = [...this.team.tags]
      this.editing = true
    },
    onSaveClick () {
      this.loading = true
      this.$VBL.team.tags.post(this.team.id, this.iTags)
        .catch(e => console.log(e.response))
        .finally(f => {
          this.cancel()
        })
    }
  }
}
</script>
